import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export const Mins = () => {


    return (
        <div>
            <div className="container">
                <div className="row">
                    <h1>MiNS - Miniature North Seeker</h1>
                    <p>For software see <Link to="/navigator">Navigator</Link></p>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="card">
                            <div className="card-header">
                                <h3>Description</h3>
                            </div>
                            <div className="card-body">
                                <p>MiNS is a miniature north seeker that can be used to determine the orientation of a drillhole.
                                    <br />The device is equipped with a gyroscope and accelerometer to determine the orientation of the drillhole.
                                    <br />The data is transmitted to a computer via USB and can be used to create a 3D model of the drillhole.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="card">
                            <div className="card-header">
                                <h3>Features</h3>
                            </div>
                            <div className="card-body">
                                <ul>
                                    <li>Small and lightweight</li>
                                    <li>Easy to use</li>
                                    <li>USB connection</li>
                                    <li>3D model creation</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}